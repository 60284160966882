import { render, staticRenderFns } from "./ErrorAlert.vue?vue&type=template&id=3da3bee8&scoped=true&"
import script from "./ErrorAlert.vue?vue&type=script&lang=js&"
export * from "./ErrorAlert.vue?vue&type=script&lang=js&"
import style0 from "./ErrorAlert.vue?vue&type=style&index=0&id=3da3bee8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3da3bee8",
  null
  
)

export default component.exports